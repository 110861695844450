<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">新建充值套餐</en-button>
    </template>
    <tabs-maintain
      :ajax="{ action: 'GET /enospray/common/lookup/:lookupType', params: (params) => (params.paths = ['STMSGTUCST']) }"
      :props="{ value: 'code', label: 'message' }"
      v-model="tabs.statusCode"
      :method="table.get"
    >
    </tabs-maintain>
    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
          <en-table-column label="套餐编号" prop="serialNo">
            <template #default="{ row }: { row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto'] }">
              <en-button text @click="table.serialNo.click(row)" copy>{{ row.serialNo }}</en-button>
            </template>
          </en-table-column>
          <en-table-column label="套餐名称" prop="name"></en-table-column>
          <en-table-column label="短信条数" prop="count"></en-table-column>
          <en-table-column label="套餐售价" prop="price"></en-table-column>
          <en-table-column label="上架状态" prop="status.message"></en-table-column>
          <en-table-column label="操作">
            <template #default="{ row }: { row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto'] }">
              <en-button type="primary" text @click="table.operation.putaway.click(row)" v-if="row.status?.code === 'P'">上架</en-button>
              <en-button type="primary" text @click="table.operation.remove.click(row)" v-if="row.status?.code === 'A'">下架</en-button>
              <en-button type="primary" text @click="table.operation.discard.click(row)" v-if="row.status?.code === 'P'">废弃</en-button>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="detail.visible" :title="!detail.form.data.id ? '新建充值套餐' : '编辑套餐'">
    <en-form :model="detail.form.data" :ref="setRef('detailForm')" :rules="detail.form.rules">
      <en-form-item label="套餐编号" prop="serialNo">
        <en-input v-model="detail.form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="套餐名称" prop="name">
        <en-input v-model="detail.form.data.name" :disabled="detail.form.data.status.code === 'A'"></en-input>
      </en-form-item>
      <en-form-item label="短信条数" prop="count">
        <en-input-number
          :controls="false"
          v-model="detail.form.data.count"
          :min="1"
          :precision="0"
          class="w-full"
          :disabled="detail.form.data.status.code === 'A'"
        ></en-input-number>
      </en-form-item>
      <en-form-item label="套餐售价" prop="price">
        <en-input-number
          :controls="false"
          v-model="detail.form.data.price"
          :min="1"
          :precision="0"
          class="w-full"
          :disabled="detail.form.data.status.code === 'A'"
        ></en-input-number>
      </en-form-item>
    </en-form>
    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click" :disabled="['A', 'D'].includes(detail.form.data.status.code)"> 确定 </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      tabs: {
        statusCode: ''
      },

      table: {
        ajax: {
          get: {
            action: 'GET /enospray/short/message/combo',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.tabs
            }
          },
          audit: {
            action: 'POST /enocloud/short/message/combo/:comboId/audit'
          },
          reverse: {
            action: 'POST /enocloud/short/message/combo/:comboId/reverse/audit'
          },
          discard: {
            action: 'POST /enocloud/short/message/combo/:comboId/discard'
          }
        },
        children: {
          operation: {
            putaway: {
              click(row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto']) {
                this.table.audit({ paths: [row.id] }).then((res) => {
                  this.table.get()
                })
              }
            },
            remove: {
              click(row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto']) {
                this.table.reverse({ paths: [row.id] }).then((res) => {
                  this.table.get()
                })
              }
            },
            discard: {
              click(row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto']) {
                this.table.discard({ paths: [row.id] }).then((res) => {
                  this.table.get()
                })
              }
            }
          }
        },
        serialNo: {
          async click(row: EnosprayShortMessageDefinitions['ShortMessageTopUpComboDto']) {
            this.detail.form.init()
            // this.detail.form.init()
            this.detail.form.data.id = row.id
            await this.detail.form.get()
            this.detail.visible = true
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              id: '',
              serialNo: '',
              name: '',
              count: 1,
              price: 0,
              status: {
                code: ''
              }
            },
            ajax: {
              get: {
                action: 'GET /enocloud/short/message/combo/:comboId',
                data: 'object',
                init: true,
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/short/message/combo',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/short/message/combo',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入套餐名称' }],
              price: [{ required: true, message: '请输入短信条数' }],
              count: [{ required: true, message: '请输入套餐售价' }]
            },
            computed: {
              formDataDisabled() {
                return this.detail.form.data.status.code === 'P' || this.detail.form.data.status.code === 'D'
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
